import { useCallback, useEffect, useState } from "react";
import { useAccountInfo, useListAccounts } from "@/api/account-management";
import {
  ChangePassword,
  ConfirmChangePassword,
  CurrentAccount,
} from "@/components";
import { useEventDetail, usePasswordStore } from "@/stores";
import {
  getDashboardRoutesData,
  Hooks,
  signOut,
  storage,
  updatePassword,
  useAccountStore,
  UserAccount,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { toast } from "@hyperlocal/vital2";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { SidebarDesktop, SidebarMobile } from "../sidebar";
import { HeaderIcon } from "./header-icon";
import { HeaderLink } from "./header-link";
import { HeaderSheet } from "./header-sheet";
import { HeaderTitle } from "./header-title";

const routes = getDashboardRoutesData();

export function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = Hooks.useMediaQuery("mobile");
  const currentState = usePasswordStore((state) => state);
  const { isAuthenticated } = useLoaderData() as Record<string, boolean>;
  const { eventDetail: header } = useEventDetail();

  const toggleUpdatePasswordOpen = usePasswordStore(
    (state) => state.toggleUpdatePasswordOpen,
  );
  const onUpdatePasswordSuccess = usePasswordStore(
    (state) => state.onUpdatePasswordSuccess,
  );
  const toggleConfirmUpdatePasswordOpen = usePasswordStore(
    (state) => state.toggleConfirmUpdatePasswordOpen,
  );
  const toggleSheetOpen = usePasswordStore((state) => state.toggleSheetOpen);

  const { updateAccountId, currentAccountId, updateFok } = useAccountStore();
  const { data: user, isLoading: isLoadingUser } = useListAccounts({
    config: {
      enabled: !!currentAccountId,
    },
  });

  const { isLoading } = useAccountInfo({
    accountId: currentAccountId,
    config: {
      enabled: !!currentAccountId,
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<UserAccount | null>(
    null,
  );

  const onUpdateAccount = useCallback(
    async (account: UserAccount) => {
      updateAccountId(account.accountId);
      updateFok(account.fok);
      setSelectedAccount(account);
      await storage.setState({
        name: account.accountName,
        accountId: account.accountId,
      });

      if (account?.accountId !== currentAccountId)
        return navigate(routes.dashboard || "");
    },
    [updateAccountId, updateFok, currentAccountId, navigate],
  );

  const handleShowAvatar = () => {
    const isRootPath = location.pathname === "/";

    if ((isMobile && isRootPath) || !isMobile)
      return (
        <div className={"flex w-full flex-row items-center justify-end"}>
          <CurrentAccount
            isLoading={isLoading || isLoadingUser}
            currentAccount={selectedAccount}
            onOpenAccountSelection={() => {
              toggleSheetOpen();
            }}
          />
        </div>
      );
  };

  useEffect(() => {
    (async () => {
      if (user?.accounts?.length) {
        const account =
          user?.accounts.find(
            (acc: UserAccount) => acc.accountId === currentAccountId,
          ) || user?.accounts[0];

        await onUpdateAccount(account);
      }
    })();
  }, [currentAccountId, onUpdateAccount, user?.accounts]);

  const shouldShowSidebarMobile =
    isMobile && location.pathname === routes.dashboard;

  async function handleChangePassword() {
    try {
      await updatePassword({
        oldPassword: currentState.oldPassword,
        newPassword: currentState.newPassword,
      });
      onUpdatePasswordSuccess();
      await signOut({ global: true });
      window.location.reload();
      window.localStorage.clear();
    } catch (error: any) {
      toggleConfirmUpdatePasswordOpen();
      toast({
        variant: "error",
        title: "Erro ao atualizar senha",
        description:
          error.message ||
          "Ocorreu um erro ao atualizar sua senha. Por favor, tente novamente mais tarde.",
      });
    }
  }

  const TitleComponent = () => {
    if (shouldShowSidebarMobile)
      return <SidebarMobile setIsOpen={setIsOpen} isOpen={isOpen} />;

    if (isMobile)
      return (
        <>
          <HeaderIcon
            icon={header?.leftIcon?.name}
            testId={header?.testId?.leftIcon || "left-header-icon"}
            onClick={header?.leftIcon?.onClick}
          />
          <HeaderTitle title={header?.title || ""} />
          <HeaderLink Link={header?.Link} testId={header?.testId} />
          <HeaderIcon
            icon={header?.rightIcon?.name}
            testId={header?.testId?.rightIcon || "right-header-icon"}
            onClick={header?.rightIcon?.onClick}
          />
        </>
      );

    return null;
  };

  if (!isAuthenticated) return null;

  return (
    <div className="relative data-[show=false]:hidden" data-show={header?.show}>
      <header
        data-testid={header?.testId?.header}
        {...header}
        className={"flex w-full items-center bg-neutral-white desktop:py-3"}
      >
        <div className={"flex w-full flex-col justify-center gap-4"}>
          <div className={"flex w-full flex-row items-center gap-2 p-4"}>
            <TitleComponent />
          </div>
        </div>
        {handleShowAvatar()}
        <HeaderSheet
          isLoading={isLoading || isLoadingUser}
          isOpen={currentState.isSheetOpen}
          onClose={toggleSheetOpen}
          currentAccount={selectedAccount}
          accounts={user?.accounts || []}
          onSelectAccount={onUpdateAccount}
          onOpenUpdatePassword={() => {
            toggleUpdatePasswordOpen();
          }}
        />
        <ChangePassword
          isOpen={currentState.isUpdatePasswordOpen}
          onClose={() => {
            toggleUpdatePasswordOpen();
          }}
          onConfirmChangePassword={() => {
            toggleConfirmUpdatePasswordOpen();
          }}
        />
        <ConfirmChangePassword
          isOpen={currentState.isConfirmUpdatePasswordOpen}
          onUpdatePassword={handleChangePassword}
          onClose={() => {
            toggleConfirmUpdatePasswordOpen();
          }}
        />
      </header>
      {!isMobile && <SidebarDesktop />}
    </div>
  );
}
